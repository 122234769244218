import 'core-js/es6';
import { environment } from './environments/environment';
require('zone.js');

if (environment.production) {
  // Production
} else {
  // Development and test
  Error['stackTraceLimit'] = Infinity;
  require('zone.js/dist/long-stack-trace-zone');
}

(window as any).global = window;
